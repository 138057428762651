import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, RouteReuseStrategy } from '@angular/router';

import { mainRoutes } from './main.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { finalize } from 'rxjs';
import { HttpClientProvider } from './services/http-client';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { ToastrModule } from 'ngx-toastr';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { TokenInterceptorService } from './services/interceptor.service';
import { CustomReuseStrategy } from './custom-reuse-strategy';


function appInitializer(userService: AuthService) {
  return () => {
    return new Promise<void>((resolve) => {
      userService.getUserByToken().pipe(
        finalize(() => resolve())
      ).subscribe();
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    importProvidersFrom(HttpClientProvider),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(AuthService),
    importProvidersFrom(ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      newestOnTop: false,
      preventDuplicates: true
    })),

    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(mainRoutes),
    provideAnimationsAsync()
  ]
};
