import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';

import { CommonModule, Location } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { environment } from '../../../environments/environment';
import { ButtonCapYeiComponent } from '../button/button.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
	imports: [
    CommonModule, 
    RouterModule, 
    MatMenuModule,
    ButtonCapYeiComponent,
    LazyLoadImageModule,
  ]
})
export class HeaderComponent implements OnInit {

  env = environment;

  private routeHistory: string[] = [];

  constructor(private router: Router, private location: Location) {}

  ngOnInit() {
  }
}
