import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IframeGuardService {
    constructor() {}

    public isInIframe(): boolean {
        return window.self !== window.top;
    }
}