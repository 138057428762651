import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';
export class CustomReuseStrategy implements RouteReuseStrategy {
    handlers: { [key: string]: DetachedRouteHandle | null } = {};
    
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return true;
    }
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
        this.handlers[route.routeConfig?.path || ''] = handle;
    }
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!this.handlers[route.routeConfig?.path || ''];
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.handlers[route.routeConfig?.path || ''] || null;
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }
    
}